<template>
	<div class="title">
		<span class="left">{{title}}</span>
		<span v-if="link && link.length>0" class="right" @click="$router.push({path: link})">查看更多&gt;</span>
	</div>
</template>
<script>
	export default {
		name: 'BlockTitle',
		props: ['title', 'link']
	}
</script>
<style lang="scss" scoped>
	.title {
		@include body_block;
		margin-top: 60px;
		margin-bottom: 60px;
		position: relative;
		.left {
			display: block;
			text-align: center;
			line-height: 50px;
			font-size: 36px;
			padding-left: 23px;
			position: relative;
			@media (max-width: $mob-screen) {
				line-height: 30px;
				font-size: 20px;
				padding-left: 10px;
			}
		}
		.right {
			position: absolute;
			top: 11px;
			right: 30px;
			line-height: 29px;
			font-size: 21px;
			color: #7a7a7a;
			cursor: pointer;
			@media (max-width: $mob-screen) {
				top: 5px;
				right: 16px;
				line-height: 20px;
				font-size: 16px;
			}
		}
		@media (max-width: $mob-screen) {
			margin-top: 16px;
			margin-bottom: 8px;
		}
	}
</style>